import { getPropertyFinancial, updatePropertyFinancial } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'

export const validationInvestmentProperty = ({ getFormValues }) => {
  const values = getFormValues()
  return values.is_investment_property ? values.annual_rent : true
}

export const actions = {
  async load (propertyId) {
    try {
      const response = await getPropertyFinancial(propertyId)
      const { price, ...payload } = response.data
      return { price: price.price, ...payload }
    } catch (error) {
      errorModal('Fout bij het laden van de financiële gegevens, probeer het opnieuw.')
      throw error
    }
  },
  async submit (propertyId, data) {
    try {
      const response = await updatePropertyFinancial(propertyId, data)
      successModal('De wijzigingen aan de financiële gegevens zijn succesvol opgeslagen')
      return response
    } catch (error) {
      errorModal('Er ging iets fout bij het wijzigen van de financiële gegevens van dit pand. Gelieve nogmaals te proberen.')
      throw error
    }
  }
}
