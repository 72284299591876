import { NUMBER_INPUT_POSITIVE } from '@/forms/schemas/generators'
import { actions } from './financial'

const schema = [
  {
    component: 'div',
    class: 'tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        type: 'text',
        name: 'cadastral_nature',
        label: 'Kadastrale aard',
        placeholder: 'Kadastrale aard',
        outerClass: 'tw-m-0'
      },
      {
        name: 'cadastral_income',
        label: 'Kadastraal inkomen',
        placeholder: 'Kadastraal inkomen',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'cadastral_income_indexed',
        label: 'Geïndexeerd kadastraal inkomen',
        placeholder: 'Geïndexeerd kadastraal inkomen',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        name: 'cadastral_income_indexed_year',
        label: 'Jaartal geïndexeerd kadastraal inkomen',
        placeholder: 'Jaartal geïndexeerd kadastraal inkomen',
        outerClass: 'tw-m-0',
        ...NUMBER_INPUT_POSITIVE
      },
      {
        type: 'text',
        name: 'cadastral_department',
        label: 'Kadastrale afdeling',
        placeholder: 'Kadastrale afdeling',
        outerClass: 'tw-m-0'
      },
      {
        type: 'text',
        name: 'cadastral_section',
        label: 'Kadastrale sectie',
        placeholder: 'Kadastrale sectie',
        outerClass: 'tw-m-0'
      },
      {
        type: 'text',
        name: 'lot_number',
        label: 'Perceelnummer',
        placeholder: 'Perceelnummer',
        outerClass: 'tw-m-0'
      }
    ]
  }
]

export default {
  heading: 'Kadastraal',
  schema,
  actions
}
