import { NICHES } from '@/forms/selectOptions'

export const NUMBER_INPUT_POSITIVE = {
  type: 'number',
  validation: 'bail|number|min:0',
  min: 0,
  lang: 'nl',
  step: 'any'
}

export const YES_NO_UNKNOWN = {
  type: 'select',
  placeholder: 'Selecteer antwoord',
  options: {
    0: 'Onbekend',
    1: 'Ja',
    2: 'Nee'
  }
}

export const YES_NO_REQUEST = {
  type: 'select',
  placeholder: 'Selecteer antwoord',
  options: {
    1: 'Ja',
    2: 'Nee',
    3: 'Niet ingegeven',
    4: 'In aanvraag'
  }
}

export const YES_NO_NOT_ENTERED = {
  type: 'select',
  placeholder: 'Selecteer antwoord',
  options: {
    1: 'Ja',
    2: 'Nee',
    3: 'Niet ingegeven'
  }
}

export const YES_NO_BOTH = {
  type: 'select',
  placeholder: 'Selecteer antwoord',
  options: {
    1: 'Ja',
    2: 'Nee',
    5: 'Beide mogelijk'
  }
}

export const SELECT_NICHE = {
  type: 'select',
  name: 'niche',
  label: 'Niche',
  placeholder: 'Selecteer niche',
  options: NICHES,
  validation: 'required',
  showRequiredLabel: true
}
